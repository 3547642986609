import SectionBox from '@/components/SectionBox';
import { FFAddGroup, FFCol, FFRow, FFSidePanel } from '@/uikit';
import FFInput from '@/uikit/components/Input';
import useFormStore from '@/stores/forms';
import FFButton from '@/uikit/components/Button';
import { Ref, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Category } from '@/models/models';
import useSWR from 'swr';
import swrFetcher from '@/utils/swrFetcher';
import { OFFERSOURCES_CATEGORIES_NOT_DELETED_URL, OFFER_CATEGORIES_NOT_DELETED_URL } from '@/constants/swrUrls';
import { ReportCategory } from '@/models/reportCategory';
import CircleButton from '@/uikit/components/CircleButton';
import useHttp from '@/hooks/http';
import { DeleteList } from '@/models/deleteList';
import { message as AntMessage } from 'antd';
import { getSidebarOffsetLevel } from '@/utils/sidebar';
import { generateEntityId } from '@/utils/id';

interface FormProps {
  closeForm: () => void;
  submitLoading: boolean;
  setSubmitLoading: (loading: boolean) => void;
  openCategoryDeleteConfirm: () => void;
}

interface RefType {
  onSave: () => void;
  onConfirmDeleteCategory: () => void;
}

const NEW_POSTFIX = '-new';

const getCategoryUrlByType = (type: ReportCategory.TypeEnum) => {
  if (type === 'offer') {
    return OFFER_CATEGORIES_NOT_DELETED_URL;
  } else if (type === 'offersources') {
    return OFFERSOURCES_CATEGORIES_NOT_DELETED_URL;
  }
};

const Form = forwardRef(({ closeForm, setSubmitLoading, submitLoading, openCategoryDeleteConfirm }: FormProps, ref: Ref<RefType>) => {
  const { del: httpDelete, post: httpPost } = useHttp();
  const categoryFormProps = useFormStore(state => state.category);

  // swr requests
  const { data: categories = [], isLoading: isGettingCategories } = useSWR<Category[]>(
    getCategoryUrlByType(categoryFormProps.categoryType),
    swrFetcher,
    {
      revalidateOnFocus: false
    }
  );

  // states
  const [categoriesList, setCategoriesList] = useState<Category[]>([]);
  const [deleteCategoryId, setDeleteCategoryId] = useState('');

  const notDeletedCategories = useMemo(() => {
    return categoriesList.filter(category => category.status !== 'deleted');
  }, [categoriesList]);

  useEffect(() => {
    if (!isGettingCategories) {
      setCategoriesList(categories);
    }
  }, [isGettingCategories, categories]);

  const onEditCategoryName = (e: React.ChangeEvent<HTMLInputElement>, categoryIdx: number) => {
    const newCategories = [...categoriesList];
    newCategories[categoryIdx].categoryName = e.target.value;
    setCategoriesList(newCategories);
  };

  const onConfirmDeleteCategory = () => {
    setCategoriesList(
      categoriesList.map((category, idx) => {
        if (category.idCategory === deleteCategoryId) {
          return { ...category, status: 'deleted' };
        }
        return category;
      })
    );
  };

  const onDeleteCategory = (category: Category) => {
    setDeleteCategoryId(category.idCategory);
    openCategoryDeleteConfirm();
  };

  const onSave = async () => {
    try {
      setSubmitLoading(true);
      const forDeleteCategoryIds = categoriesList
        .filter(category => category.status === 'deleted' && !category.idCategory.includes(NEW_POSTFIX))
        .map(category => category.idCategory);
      if (forDeleteCategoryIds.length) {
        await httpDelete<DeleteList>('v1/category/delete/', { data: { entries: forDeleteCategoryIds } });
      }
      for (const category of categoriesList) {
        if (category.idCategory.includes(NEW_POSTFIX) && category.status !== 'deleted') {
          await httpPost<Category, Category>('v1/category/save/', {
            ...category,
            idCategory: generateEntityId()
          });
        }
      }
      AntMessage.success('Categories deleted successfully');
      closeForm();
    } catch (e) {
      AntMessage.error('Categories could not get deleted');
    } finally {
      setSubmitLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({ onConfirmDeleteCategory, onSave }));

  const onAddCategory = () => {
    const newCategories = [...categoriesList];
    const newCategory: Category = {
      idCategory: `${generateEntityId()}${NEW_POSTFIX}`,
      categoryName: '',
      categoryType: categoryFormProps.categoryType,
      status: 'active'
    };
    newCategories.push(newCategory);
    setCategoriesList(newCategories);
  };

  return (
    <FFRow width="100%">
      <SectionBox>
        <FFAddGroup
          showAddRow
          onAddRow={onAddCategory}
          rows={notDeletedCategories}
          renderRow={(row, rowIdx) => {
            return (
              <FFAddGroup.Col>
                <FFAddGroup.Row>
                  <FFInput name="name" value={row.categoryName} placeholder="Name" onChange={e => onEditCategoryName(e, rowIdx)} />
                  <CircleButton iconName="general/line/delete-circle" onClick={() => onDeleteCategory(row)} />
                </FFAddGroup.Row>
              </FFAddGroup.Col>
            );
          }}
        />
      </SectionBox>
    </FFRow>
  );
});

const CategoryForm = () => {
  const isOpen = useFormStore(state => state.category.isOpen);
  const isOfferFormOpen = useFormStore(state => state.offer.isOpen);
  const openedForms = useFormStore(state => state.openedForms);
  const closeForm = useFormStore(state => state.closeCategoryForm);
  const formRef = useRef<RefType>({
    onSave: () => {},
    onConfirmDeleteCategory: () => {}
  });
  const [isDeleteCategoryOpen, setIsDeleteCategoryOpen] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const onConfirmDelete = () => {
    setIsDeleteCategoryOpen(false);
    formRef.current.onConfirmDeleteCategory();
  };

  return (
    <>
      <FFSidePanel
        isOpen={isDeleteCategoryOpen}
        maxWidth={500}
        onClose={() => setIsDeleteCategoryOpen(false)}
        sidebarName="DeleteCategory"
        zIndex={103}
        title="Delete Category"
        actions={
          <FFRow gap="8px">
            <FFButton onClick={onConfirmDelete}>Yes</FFButton>
            <FFButton color="grayColor" onClick={() => setIsDeleteCategoryOpen(false)}>
              No
            </FFButton>
          </FFRow>
        }
      >
        <FFCol>
          <p>Deleting this category will move all offers in it to Uncategorized.</p>
          <p>
            <strong>Are you sure you want to delete this category? (this action is not reversible).</strong>
          </p>
        </FFCol>
      </FFSidePanel>
      <FFSidePanel
        isOpen={isOpen}
        minWidth={700}
        offsetLevel={getSidebarOffsetLevel(openedForms, 'category') + (isDeleteCategoryOpen ? 1 : 0)}
        onClose={closeForm}
        sidebarName="CategoryForm"
        zIndex={isOfferFormOpen ? 101 : 100}
        title="Categories"
        actions={
          <FFRow gap="8px">
            <FFButton onClick={() => formRef.current.onSave()} loading={submitLoading} disabled={submitLoading}>
              Save
            </FFButton>
            <FFButton variant="outlined" disabled={submitLoading} onClick={closeForm}>
              Cancel
            </FFButton>
          </FFRow>
        }
      >
        <Form
          closeForm={closeForm}
          setSubmitLoading={setSubmitLoading}
          openCategoryDeleteConfirm={() => setIsDeleteCategoryOpen(true)}
          submitLoading={submitLoading}
          ref={formRef}
        />
      </FFSidePanel>
    </>
  );
};

export default CategoryForm;
