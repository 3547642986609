const urlSpecialChars = ['@', '^', '$', '#', '%', '*', '`', '(', ')', '~'];
const MAX_SPECIAL_CHARS_COUNT = 10;

export function validateUrl(value: string) {
  try {
    const url = new URL(value);
    const specialCharsCount = urlSpecialChars.reduce((acc, item) => {
      acc += value.split(item).length - 1;
      return acc;
    }, 0);
    const isValidProtocol =
      /^(https?|ftp|callto|geo|mailto|maps|bip|bbmi|itms-services|fb-me|fb-messenger|intent|line|skype|sms|snapchat|tel|tg|threema|twitter|viber|webcal|web\+mastodon|wh|whatsapp):$/i.test(
        url.protocol
      );
    if (!isValidProtocol || !(specialCharsCount < MAX_SPECIAL_CHARS_COUNT)) {
      return 'Not a valid URL';
    }
    return false;
  } catch (e) {
    return 'Not a valid URL';
  }
}
