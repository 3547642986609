import useHttp from '@/hooks/http';
import useSystemSettingsStore from '../stores/systemSettings';
import { UserSettings } from '@/models/userSettings';

const useSystemSettingsApi = () => {
  const { get } = useHttp();
  const setUserSettings = useSystemSettingsStore(state => state.setUserSettings)

  async function fetchUserSettings() {
    const res = await get<UserSettings>('/v1/user/settings/');
    setUserSettings(res.data);
  }

  return { fetchUserSettings };
};

export default useSystemSettingsApi;