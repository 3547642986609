import { Category } from '@/models/category';
import { Funnel } from '@/models/funnel';
import { FunnelCondition } from '@/models/funnelCondition';
import { FunnelGroup } from '@/models/funnelGroup';
import { OfferSource } from '@/models/offerSource';
import { Page } from '@/models/page';
import { PageGroup } from '@/models/pageGroup';
import { TrafficSource } from '@/models/trafficSource';
import { defined } from './define';

type EntityType = Page | PageGroup | Category | OfferSource | TrafficSource | FunnelCondition | Funnel | FunnelGroup;

export const getActiveEntities = <T extends { filter: Function }>(entities: T): T => {
  if (Array.isArray(entities)) {
    return (entities as T).filter((item: EntityType) => !defined(item.status) || item.status === 'active');
  }
  return entities;
};
