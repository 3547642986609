import { SystemSettingsStore, SystemSettingsStoreProps } from '@/types/stores/systemSettings';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const DEFAULT_PROPS: SystemSettingsStoreProps = {
  domains: [],
  showEditModeTooltip: false,
  showProductTourButtons: false,
  userSettings: {
    defaultHomepageURL: '',
    defaultOfferRedirect: '307',
    defaultLanderRedirect: '307',
    ipAnonymizer: 'All IPs',
    defaultCustomDomain: '',
    thirdPartyKeys: {
      clickbankIPN: ''
    }
  }
};

const useSystemSettingsStore = create<SystemSettingsStore>()(
  devtools(set => ({
    ...DEFAULT_PROPS,
    setDomains: domains => set({ domains }),
    setShowEditModeTooltip: showEditModeTooltip => set({ showEditModeTooltip }),
    setShowProductTourButtons: showProductTourButtons => set({ showProductTourButtons }),
    setUserSettings: userSettings => set({ userSettings })
  }))
);

export default useSystemSettingsStore;
