export const jsCodes = {
  globalCode: {
    preCodeComment: `<!-- FF Pro Universal Tag -->`,
    code: `<script>!function(f,l,u,x,j,s,a,b){window.flux||(j="undefined"!=typeof fluxOptions?fluxOptions:{},s="undefined"!=typeof fluxDefaults?fluxDefaults:{},(a=l.createElement("script")).src="https://"+u+"/integration/lumetricv2.min.js?v="+x,a.type="text/javascript",a.async="true",queue=[],window.flux={track:function(){queue.push(arguments)}},a.onload=a.onreadystatechange=function(){var rs=this.readyState;if(!rs||"complete"==rs||"loaded"==rs)try{for(window.flux=new Lumetric(u,x,j,s);0!=queue.length;){var args=queue.pop();window.flux.track.apply(null,args)}}catch(e){}},(b=document.getElementsByTagName("script")[0]).parentNode.insertBefore(a,b))}(window,document,"{DOMAIN}","3.3.0");</script>`
  },
  pageView: {
    preCodeComment: `<!-- FF Pro View Event -->`,
    eventCode: `<script>\n flux.track("view")\n</script>`
  },
  offerConversion: {
    preCodeComment: `<!-- FF Pro Conversion Event -->`,
    eventCode: `<script>\n flux.track("conversion",{rev:"{REVENUE}",tx:"{TRANSACTION}"})\n</script>`
  },
  genericConversion: {
    preCodeComment: `<!-- FF Pro Conversion Event -->`,
    eventCode: `<script>\n flux.track("conversion",{rev:"CONVERSION_VALUE",tx:"OPTIONAL_TXID"})\n</script>`
  }
};
