import config from '@/config';
import axios from 'axios';
import { getCookie } from './storage';
import { FF_ACCESS_TOKEN } from '@/constants/auth';

const axiosInstance = axios.create({
  baseURL: config.apiUrls.funnelflux,
});

axiosInstance.interceptors.request.use(config => {
  const accessToken = getCookie(FF_ACCESS_TOKEN);
  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
});

const swrFetcher = async (url: string) => {
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw new Error('An error occurred while fetching data');
  }
};

export default swrFetcher;
