import React, { PropsWithChildren, CSSProperties } from 'react';
import './style.scss';
import clsx from 'clsx';

const Row = ({
    children,
    className,
    ...rest
  }: PropsWithChildren & CSSProperties & { className?: string }) => (
  <div className={clsx('c-row', className)} style={rest}>
    {children}
  </div>
);

export default Row;