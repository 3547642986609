import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <g id="button-play--button-television-buttons-movies-play-tv-video-controls">
      <path
        id="Union"
        fill="currentColor"
        fill-rule="evenodd"
        d="M5.147708571428571 0.8571428571428571c-0.4793314285714286 0 -0.95064 0.12208114285714285 -1.3695428571428572 0.35456742857142853 -0.42696 0.2178788571428571 -0.7873542857142857 0.5470097142857143 -1.0429885714285714 0.9527982857142857 -0.25954285714285713 0.41196 -0.40119428571428567 0.88716 -0.4095942857142857 1.3739828571428572l-0.00011999999999999999 0v16.90830857142857h-0.00011999999999999999l0.00023999999999999998 0.014742857142857143c0.0084 0.48685714285714277 0.15005142857142856 0.9620571428571428 0.4095942857142857 1.3739999999999999 0.2556342857142857 0.40577142857142856 0.6160285714285714 0.7349142857142857 1.0429885714285714 0.9527999999999999 0.41890285714285713 0.23245714285714283 0.8902114285714285 0.3545142857142857 1.3695428571428572 0.3545142857142857 0.4865142857142857 0 0.9647657142857141 -0.12582857142857143 1.3883485714285713 -0.3651428571428571 0.009857142857142856 -0.005485714285714286 0.019594285714285713 -0.011314285714285714 0.02921142857142857 -0.017314285714285713L20.23885714285714 14.303365714285713c0.42428571428571427 -0.21433714285714284 0.7823999999999999 -0.5403428571428571 1.0354285714285714 -0.94332 0.2619428571428571 -0.4169485714285714 0.40097142857142853 -0.8993485714285714 0.40097142857142853 -1.391742857142857s-0.13902857142857142 -0.9747942857142856 -0.40097142857142853 -1.391742857142857c-0.2533714285714285 -0.40342285714285714 -0.612 -0.7297028571428571 -1.0368 -0.94404L6.562817142857143 1.2380211428571428C6.554005714285714 1.2326039999999998 6.545074285714285 1.2273462857142856 6.536057142857143 1.2222514285714283 6.112474285714285 0.982914857142857 5.634222857142857 0.8571428571428571 5.147708571428571 0.8571428571428571Z"
        clip-rule="evenodd"
        stroke-width="1"
      ></path>
    </g>
  </svg>
);
