import { Page } from '@/models/page';
import { FFSelectOption } from '@/uikit/types/select';

export const redirects: FFSelectOption<string, Page.RedirectTypeEnum>[] = [
  {
    value: '301',
    label: '301 Moved Permanently'
  },
  {
    value: '307',
    label: '307 Temporary Redirect'
  },
  {
    value: 'umr',
    label: 'Ultimate Meta Refresh'
  }
];

export const additionalRedirects: FFSelectOption[] = [
  {
    value: '301',
    label: '301 Moved Permanently'
  },
  {
    value: '307',
    label: '307 Temporary Redirect'
  },
  {
    value: 'meta',
    label: 'Simple Meta Refresh'
  },
  {
    value: 'umr',
    label: 'Ultimate Meta Refresh'
  }
];
