import React, { PropsWithChildren } from 'react';
import { VisibilityWrapper } from '@/uikit';
import ReactPortal from '@/components/Portal';
import className from '@/utils/className';
import './style.scss';
import { SidebarTab } from '@/uikit/types/sidebar';
import clsx from 'clsx';

const { blockClassName, getClass } = className('c-ffSidePanel');

interface Props {
  title: string | React.ReactNode;
  actions?: React.ReactNode;
  tabs?: SidebarTab[];
  sidebarName: string;
  zIndex?: number;
  isOpen: boolean;
  onClose: () => void;
  defaultTabId?: string;
  minWidth?: number;
  maxWidth?: number;
  offsetLevel?: number;
  setCurrentTabId?: (tabId: string) => void;
}

const SidePanel = ({
  zIndex = 100,
  sidebarName,
  isOpen,
  onClose,
  title,
  actions,
  children,
  tabs = [],
  minWidth,
  maxWidth,
  defaultTabId,
  offsetLevel,
  setCurrentTabId = () => {}
}: PropsWithChildren<Props>) => {
  const hasTabs = tabs.length > 0;
  const [currentTabIdState, setCurrentTabIdState] = React.useState(defaultTabId);

  const onTabClick = (tab: SidebarTab) => {
    setCurrentTabIdState(tab.tabId);
    setCurrentTabId(tab.tabId);
  };

  return (
    <ReactPortal zIndex={zIndex} dataPortalKey={sidebarName}>
      <>
        <div className={getClass('backdrop', [isOpen && 'open'])} style={{ zIndex }} onClick={onClose} />
        <div
          style={{ zIndex, minWidth, maxWidth }}
          className={clsx(blockClassName, `${blockClassName}--${isOpen ? 'open' : 'close'}`, `${blockClassName}--offsetLevel${offsetLevel}`)}
        >
          <div className={getClass('header')}>
            <h3 className={getClass('title')}>{title}</h3>
            <div className={getClass('actions')}>{actions}</div>
          </div>
          <div className={getClass('body')}>
            {isOpen && <div className={getClass('children')}>{children}</div>}
            <VisibilityWrapper visible={hasTabs}>
              <div className={getClass('tabs')}>
                {tabs.map((tab, index) => (
                  <div
                    key={index}
                    className={getClass('tab', [
                      !!tab.hidden && 'hidden',
                      !!tab.highlightable && 'highlightable',
                      tab.tabId === currentTabIdState ? 'active' : 'inactive'
                    ])}
                    onClick={() => onTabClick(tab)}
                  >
                    <span className={getClass('tabTitle')}>{tab.title}</span>
                    {tab.icon}
                  </div>
                ))}
              </div>
            </VisibilityWrapper>
          </div>
        </div>
      </>
    </ReactPortal>
  );
};

export default SidePanel;
