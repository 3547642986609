import { PropsWithChildren, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({
  children,
  dataPortalKey,
  className,
  zIndex
}: PropsWithChildren<{ className?: string; dataPortalKey: string; zIndex?: number }>) => {
  const [el] = useState(() => document.createElement('div'));
  const appRoot = document.getElementById('root')!;

  useEffect(() => {
    el.setAttribute('data-portal-key', dataPortalKey);
    if (className) {
      el.classList.add(className);
    }
    el.style.zIndex = `${zIndex || 0}`;
    appRoot.appendChild(el);

    return () => {
      appRoot.removeChild(el);
    };
  }, []);

  return createPortal(children, el);
};

export default Portal;
