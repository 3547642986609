interface GenIdOptions {
  timestamp?: number;
  randomPart?: string; // for testing
}

// Entity ID is base62-encoded a 12 character string
//
// dddddddrrrrr
// | tsd ||rnd|
// tsd - timestamp delta base62-encoded (7 chars)
// rnd - random part                    (5 chars)
//
// we encode timestamp delta from base timestamp, BASE_TIMESTAMP value was chosen so to get always 7 chars after base62 encoding
// after Feb 25 2131 length of encoded timestamp delta will be 8 chars, but this is too far in future.

// NOTE: before changing values below think twice
const TIMESTAMP_PART_LEN = 7;
const BASE_TIMESTAMP = Date.UTC(2019, 6, 23, 0, 0, 0, 0);

// to add additional randomness we append random base62 chars after timestamp part
const RANDOM_PART_LEN = 5;
const ID_LEN = TIMESTAMP_PART_LEN + RANDOM_PART_LEN;
const BASE62_CHARS = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

export const generateEntityId = (options: GenIdOptions = {}) => {
  const { timestamp = Date.now(), randomPart = generateRandomString(RANDOM_PART_LEN) } = options;
  if (timestamp < BASE_TIMESTAMP) {
    throw new Error(`timestamp must be equal or greater than base timestamp: ${BASE_TIMESTAMP}`);
  }
  const id = encodeNumberAsBase62(timestamp - BASE_TIMESTAMP, TIMESTAMP_PART_LEN) + randomPart;
  if (id.length !== ID_LEN) {
    throw new Error(`failed to generate id: length is invalid (id=${id})`);
  }
  return id;
};

function generateRandomString(len: number): string {
  const s: string[] = [];
  for (let i = 0; i < len; i++) {
    s.push(BASE62_CHARS.charAt(Math.floor(Math.random() * BASE62_CHARS.length)));
  }
  return s.join('');
}

function encodeNumberAsBase62(n: number, len: number): string {
  n = Math.floor(Math.abs(n));
  const s: string[] = [];
  while (n !== 0) {
    s.push(BASE62_CHARS.charAt(n % 62));
    n = Math.floor(n / 62);
  }
  while (s.length < len) {
    s.push('0');
  }
  return s.reverse().join('');
}
