import React, { PropsWithChildren, createContext, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import className from '@/utils/className';
import './style.scss';
import VisibilityWrapper from '../VisibilityWrapper';
import { FFNewIcon } from '@/uikit';
import { createPortal } from 'react-dom';

const { blockClassName, getClass } = className('c-ffLayout');

interface LayoutContext {
  openedMenues: string[];
  openedSecondLevelMenues: string[];
  setOpenedMenues: (activeMenu: string) => void;
  setOpenedSecondLevelMenues: (activeMenu: string) => void;
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isSidebarOpen: boolean) => void;
}

const LayoutContext = createContext<LayoutContext | null>(null);

interface LayoutHeaderProps {}

const FFLayout = ({ children }: PropsWithChildren<{}>) => {
  const [openedMenues, setOpenedMenues] = React.useState<string[]>([]);
  const [openedSecondLevelMenues, setOpenedSecondLevelMenues] = React.useState<string[]>([]);
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  const onSetOpenedMenues = (activeMenu: string) => {
    if (!openedMenues.includes(activeMenu)) {
      setOpenedMenues([...openedMenues, activeMenu]);
    } else {
      setOpenedMenues(openedMenues.filter(menu => menu !== activeMenu));
    }
  };

  const onSetOpenedSecondLevelMenues = (activeMenu: string) => {
    if (!openedSecondLevelMenues.includes(activeMenu)) {
      setOpenedSecondLevelMenues([...openedSecondLevelMenues, activeMenu]);
    } else {
      setOpenedSecondLevelMenues(openedSecondLevelMenues.filter(menu => menu !== activeMenu));
    }
  };

  const onSetIsSidebarOpen = (isSidebarOpen: boolean) => {
    setOpenedMenues([]);
    setIsSidebarOpen(isSidebarOpen);
  };

  return (
    <LayoutContext.Provider
      value={{
        openedMenues,
        setOpenedMenues: onSetOpenedMenues,
        isSidebarOpen,
        setIsSidebarOpen: onSetIsSidebarOpen,
        openedSecondLevelMenues,
        setOpenedSecondLevelMenues: onSetOpenedSecondLevelMenues
      }}
    >
      <div className={blockClassName}>{children}</div>
    </LayoutContext.Provider>
  );
};

const FFHeader = ({ children }: PropsWithChildren<LayoutHeaderProps>) => {
  return <div className={getClass('header')}>{children}</div>;
};

const FFSider = ({
  children,
  collapsed,
  className = '',
  onToggle,
  onCreateNewAsset
}: PropsWithChildren<{ collapsed: boolean; className?: string; onToggle: () => void; onCreateNewAsset: () => void }>) => {
  const { setIsSidebarOpen } = React.useContext(LayoutContext)!;
  useEffect(() => {
    setIsSidebarOpen(!collapsed);
  }, [collapsed]);

  return (
    <div className={[getClass('sidebar', collapsed ? 'collapsed' : 'expanded'), className].join(' ')}>
      <div className={getClass('sidebarHeader')}>
        <div className={getClass('sidebarHeaderIconWrapper')} onClick={onToggle}>
          <div className={getClass('logoIcon')}>
            <FFNewIcon name="general/funnelflux" size="navigation-md" />
          </div>
          <div className={getClass('expandIcon')}>
            <FFNewIcon name="navigation/sidebar-expand" size="navigation-md" />
          </div>
          <span className={getClass('sidebarHeaderTitle')}>FunnelFlux Pro</span>
        </div>
        <div className={getClass('collapseSidebar')} onClick={onToggle}>
          <FFNewIcon name="navigation/sidebar-collapse" size="navigation-md" />
        </div>
      </div>
      <div className={getClass('createNew')}>
        <button className={getClass('createNewButton')} onClick={onCreateNewAsset} data-onboarding-id="CreateNew">
          <FFNewIcon name="general/line/plus" size="sm" className={getClass('createPlusIcon')} />{' '}
          <VisibilityWrapper visible={!collapsed}>
            <span>Create Asset</span>
          </VisibilityWrapper>
        </button>
      </div>
      {children}
    </div>
  );
};

const FFMenu = ({ children }: PropsWithChildren<{}>) => <ul className={getClass('menuWrapper')}>{children}</ul>;

const MenuItemWrapper = ({
  to,
  children,
  isOpen,
  level,
  disabled,
  onMouseOver,
  onMouseLeave,
  ...props
}: PropsWithChildren<{
  to?: string;
  isOpen?: boolean;
  disabled?: boolean;
  level?: number;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
}>) =>
  to ? (
    <li role="menuitem" onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} {...props}>
      <NavLink to={to} className={getClass('menuItem', disabled && 'disabled')}>
        {children}
      </NavLink>
    </li>
  ) : (
    <li
      className={[
        getClass('menuItem', [isOpen ? 'open' : 'closed', disabled ? 'disabled' : '']),
        !level ? getClass('menuItemFirstLevel') : getClass('menuItemSecondLevel')
      ].join(' ')}
      role="menuitem"
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      {...props}
    >
      {children}
    </li>
  );

const FFMenuItem = ({
  children,
  icon,
  title,
  onTitleClick = () => {},
  popupClassName = '',
  className = '',
  disabled = false,
  to,
  single,
  level,
  ...props
}: PropsWithChildren<{
  icon: React.ReactNode;
  title: string;
  disabled?: boolean;
  onTitleClick?: () => void;
  popupClassName?: string;
  className?: string;
  to?: string;
  single?: boolean;
  level?: number;
}>) => {
  const { setOpenedMenues, openedMenues, isSidebarOpen, setOpenedSecondLevelMenues, openedSecondLevelMenues } = React.useContext(
    LayoutContext
  )!;
  const myRef = useRef<HTMLDivElement>(null);
  const [left, setLeft] = React.useState(0);
  const [top, setTop] = React.useState(0);
  const [showPopup, setShowPopup] = React.useState(false);
  const isOpen = openedMenues.includes(title) || openedSecondLevelMenues.includes(title);
  const onSubMenuTitleClick = () => {
    if (!isSidebarOpen && showPopup) return;
    if (level === 2) {
      setOpenedSecondLevelMenues(title);
    } else {
      setOpenedMenues(title);
    }
    onTitleClick();
  };

  const onSetShowPopup = (show: boolean) => {
    if (!single && !level && !isSidebarOpen && !disabled) {
      setShowPopup(show);
    }
  };

  useEffect(() => {
    const element = myRef.current;
    setTimeout(() => {
      setLeft(element!.getBoundingClientRect().width);
      setTop(element!.getBoundingClientRect().top + element!.getBoundingClientRect().height);
    }, 400);
  }, [isSidebarOpen]);

  const showMenuPopup = () => onSetShowPopup(true)
  const hideMenuPopup = () => onSetShowPopup(false)

  return (
    <MenuItemWrapper
      isOpen={isOpen}
      to={to}
      level={level}
      disabled={disabled}
      onMouseOver={showMenuPopup}
      onMouseLeave={hideMenuPopup}
      {...props}
    >
      <div ref={myRef} role="menuitem" className={getClass('menuItemContent', disabled && 'disabled')} onClick={onSubMenuTitleClick}>
        {icon}
        <span className={getClass('menuItemTitle')}>{title}</span>
        <VisibilityWrapper visible={!to && !single}>
          <FFNewIcon name="navigation/arrow-down" size="sm" className={getClass('menuItemIcon')} />
        </VisibilityWrapper>
      </div>
      <ul className={getClass('menuItemSubItems')} role="menu">
        {children}
      </ul>
      {showPopup &&
        createPortal(
          <div className={getClass('menuItemPopup')} style={{ left: left + 10, top: top - 50 }}>
            <ul className={getClass('menuItemSubItems')} role="menu">
              {children}
            </ul>
          </div>,
          document.body
        )}
    </MenuItemWrapper>
  );
};

const FFMain = ({ children }: PropsWithChildren<{}>) => <div className={getClass('main')}>{children}</div>;

const FFContent = ({ children }: PropsWithChildren<{}>) => <div className={getClass('content')}>{children}</div>;

const FFMenuSubItem = ({
  children,
  to,
  href,
  onClick,
  disabled,
  ...props
}: PropsWithChildren<{ to?: string; href?: string; onClick?: React.MouseEventHandler<HTMLLIElement>; disabled?: boolean }>) =>
  to ? (
    <li role="menuitem" {...props}>
      <NavLink to={to} className={getClass('menuSubItem', disabled && 'disabled')}>
        {children}
      </NavLink>
    </li>
  ) : href ? (
    <li role="menuitem" onClick={onClick} {...props}>
      <a href={href} className={getClass('menuSubItem', disabled && 'disabled')} target="_blank" rel="noreferrer">
        {children}
      </a>
    </li>
  ) : (
    <li role="menuitem" className={getClass('menuSubItem', disabled && 'disabled')} onClick={onClick} {...props}>
      {children}
    </li>
  );

const FFMenuFooter = ({ children }: PropsWithChildren<{}>) => <div className={getClass('menuFooter')}>{children}</div>;

const FFMenuFooterItem = ({
  children,
  onClick = () => {},
  href,
  visible = true,
  unreadChatCount = 0,
  ...props
}: PropsWithChildren<{ onClick?: React.MouseEventHandler<HTMLDivElement>; href?: string; visible?: boolean; unreadChatCount?: number }>) =>
  visible ? (
    href ? (
      <a href={href} target="_blank" rel="noreferrer" {...props}>
        <div className={getClass('menuFooterItem')} onClick={onClick}>
          {unreadChatCount > 0 && <div className={getClass('unreadChatCount')}>{unreadChatCount}</div>}
          {children}
        </div>
      </a>
    ) : (
      <div className={getClass('menuFooterItem')} onClick={onClick} {...props}>
        {unreadChatCount > 0 && <div className={getClass('unreadChatCount')}>{unreadChatCount}</div>}
        {children}
      </div>
    )
  ) : (
    <></>
  );

FFLayout.Header = FFHeader;
FFLayout.Sider = FFSider;
FFLayout.Main = FFMain;
FFLayout.Menu = FFMenu;
FFLayout.FFMenuItem = FFMenuItem;
FFLayout.FFMenuSubItem = FFMenuSubItem;
FFLayout.Content = FFContent;
FFLayout.MenuFooter = FFMenuFooter;
FFLayout.MenuFooterItem = FFMenuFooterItem;

export { FFLayout, FFHeader, FFSider, FFMenu, FFMenuItem, FFMenuSubItem, FFContent, FFMenuFooter, FFMenuFooterItem };
