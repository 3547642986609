import React, { PropsWithChildren } from 'react';
import { Switch, SwitchProps } from 'antd';
import './style.scss';
// import { IconTooltip } from 'components/Parts/Tooltip';
import VisibilityWrapper from '../VisibilityWrapper';
import clsx from 'clsx';

const FFSwitch = ({
  onClick,
  checked = false,
  className,
  children,
  id,
  tooltip,
  dataTestId,
  disabled = false,
  loading,
  redOnOff = false,
  wrapperClassname = ''
}: PropsWithChildren<SwitchProps> & {
  tooltip?: JSX.Element[];
  dataTestId?: string;
  wrapperClassname?: string;
  redOnOff?: boolean;
}) => (
  <div
    className={clsx(['c-ffSwitch', wrapperClassname])}
    data-testid={dataTestId}
  >
    <Switch
      checked={checked}
      onClick={onClick}
      id={id}
      className={clsx('c-ffSwitch__button', {
        [`${className}--enabled`]: !!className && checked,
        [`${className}--disabled`]: !!className && !checked,
        [`c-ffSwitch--disabled`]: !checked,
        [`c-ffSwitch--enabled`]: checked,
        'c-ffSwitch--disabledCompletely': disabled,
        'c-ffSwitch--redOnOff': redOnOff,
      })}
      disabled={disabled}
      loading={loading}
    />
    <VisibilityWrapper visible={!!children}>
      <label className="c-ffSwitch__label" htmlFor={id}>
        {children}
      </label>
    </VisibilityWrapper>
    <VisibilityWrapper visible={!!tooltip}>
      <></>
      {/* <IconTooltip className="switchButton" body={tooltip as any} /> */}
    </VisibilityWrapper>
  </div>
);

export default FFSwitch;