import React from 'react';
import NewIcon from '../NewIcon';
import className from '@/utils/className';
import { NewIconName, NewIconSize } from '@/uikit/types/icon';
import './style.scss';

const { getClass } = className('ff-circleButton');

interface CircleButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  iconName: NewIconName;
  iconSize?: NewIconSize;
  type?: 'delete' | 'general';
  color?: 'blue' | 'gray';
}

const CircleButton: React.FC<CircleButtonProps> = ({ iconName, iconSize = 'md', type = 'delete', color = 'gray', onClick = () => {} }) => {
  const onButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onClick!(e);
  };

  return (
    <button className={getClass('button', [type, color])} onClick={onButtonClick}>
      <NewIcon name={iconName} size={iconSize} />
    </button>
  );
};

export default CircleButton;
