import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Input from 'antd/lib/input/Input';
import { Tooltip } from 'antd';
import './style.scss';
import { FFInputProps } from '@/uikit/types/input';
import className from '@/utils/className';
import clsx from 'clsx';

const { blockClassName, getClass } = className('c-ffInput');

const hasError = (error?: string) => typeof error === 'string' && error.length > 0;

function FFInput({
  disabled,
  value,
  onChange,
  style,
  className = '',
  placeholder,
  forwardedRef,
  error,
  readOnly = false,
  ...props
}: FFInputProps) {
  return (
    <Input
      readOnly={readOnly}
      className={clsx([blockClassName, className], {
        [`${blockClassName}--sm`]: props.size === 'small',
        [`${blockClassName}--md`]: props.size === 'middle',
        [`${blockClassName}--lg`]: props.size === 'large',
        [`${blockClassName}--readOnly`]: readOnly
      })}
      disabled={disabled}
      value={value}
      onChange={onChange}
      style={style}
      status={hasError(error) ? 'error' : ''}
      placeholder={placeholder}
      ref={forwardedRef}
      suffix={
        hasError(error) ? (
          <Tooltip placement="top" title={error} className={getClass('tooltip')} overlayClassName={getClass('tooltipOverlay')}>
            <ExclamationCircleOutlined style={{ color: '#ff4d4f' }} />
          </Tooltip>
        ) : (
          <></>
        )
      }
      {...props}
    />
  );
}

export default FFInput;
