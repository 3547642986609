import { useRoutes } from 'react-router-dom';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';
import useAuthStore from '@/stores/auth';

export const AppRoutes = () => {
  const access_token = useAuthStore(state => state.tokens.access_token);
  const routes = access_token ? protectedRoutes : publicRoutes;
  const element = useRoutes([...routes]);
  return <>{element}</>;
};
