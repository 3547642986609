import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Spin } from 'antd';
import { useEffect } from 'react';
import useSystemSettingsApi from '@/hooks/useSystemSettingsApis';
import Layout from '@/components/Layout';
import { lazyImport } from '@/utils/lazyImport';
import { FFRow } from '@/uikit';
import useDomainsApi from '@/hooks/useDomainsApis';
import OfferForm from '@/components/Forms/Offer';
import OfferSourceForm from '@/components/Forms/OfferSource';
import CategoryForm from '@/components/Forms/Category';

const { OfferRoutes } = lazyImport(() => import('@/features/Offers'), 'OfferRoutes');
const { OfferSourceRoutes } = lazyImport(() => import('@/features/OfferSources'), 'OfferSourceRoutes');

const App = () => {
  const { fetchUserSettings } = useSystemSettingsApi();
  const { fetchDomains } = useDomainsApi();

  useEffect(() => {
    fetchUserSettings();
    fetchDomains();
  }, []);

  return (
    <>
      <OfferForm />
      <OfferSourceForm />
      <CategoryForm />
      <Layout>
        <Suspense
          fallback={
            <FFRow height="100vh" alignItems="center" justifyContent="center">
              <Spin />
            </FFRow>
          }
        >
          <Outlet />
        </Suspense>
      </Layout>
    </>
  );
};

export const protectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: '/offers/*', element: <OfferRoutes /> },
      { path: '/offersources/*', element: <OfferSourceRoutes /> }
    ]
  },
  {
    path: '*',
    element: <Navigate to="/summary" />
  }
];
