import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <g id="block-1--remove-circle-garbage-trash-delete">
      <path
        id="Union"
        fill="currentColor"
        fill-rule="evenodd"
        d="M7.273045714285714 4.848685714285714C8.628068571428571 3.9512228571428567 10.253005714285715 3.4285714285714284 12 3.4285714285714284c4.733862857142857 0 8.571428571428571 3.837565714285714 8.571428571428571 8.571428571428571 0 1.7469942857142855 -0.5226857142857143 3.3719314285714286 -1.4201142857142857 4.726954285714285L7.273045714285714 4.848685714285714ZM4.848685714285714 7.273045714285714l11.87826857142857 11.87826857142857C15.371931428571429 20.048742857142855 13.746994285714287 20.57142857142857 12 20.57142857142857c-4.733862857142857 0 -8.571428571428571 -3.837565714285714 -8.571428571428571 -8.571428571428571 0 -1.7469942857142855 0.5226514285714285 -3.3719314285714286 1.4201142857142857 -4.726954285714285ZM12 0C5.3725885714285715 0 0 5.3725885714285715 0 12c0 6.627428571428571 5.3725885714285715 12 12 12 6.627428571428571 0 12 -5.372571428571428 12 -12 0 -6.6274114285714285 -5.372571428571428 -12 -12 -12Z"
        clip-rule="evenodd"
        stroke-width="1"
      ></path>
    </g>
  </svg>
);
