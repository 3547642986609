import useHttp from '@/hooks/http';
import useSystemSettingsStore from '../stores/systemSettings';
import { CloudflaredDomain } from '@/models/models';

const useDomainsApi = () => {
  const { get } = useHttp();
  const setDomains = useSystemSettingsStore(state => state.setDomains)

  async function fetchDomains() {
    const res = await get<CloudflaredDomain[]>('/v1/edge/domains/');
    setDomains(res.data);
  }

  return { fetchDomains };
};

export default useDomainsApi;