import React, { PropsWithChildren } from 'react';
import Button from 'antd/lib/button';
import VisibilityWrapper from '../VisibilityWrapper';
import NewIcon from '../NewIcon';
import { NewIconName, NewIconSize } from '../../types/icon';
import userPilot from '@/widgets/userpilot';
import './style.scss';

const ButtonLoading = () => (
  <div className="c-ffButton__loadingIndicator" data-testid="button-loading">
    <span className="c-ffButton__loadingDot">&#9679;</span>
    <span className="c-ffButton__loadingDot">&#9679;</span>
    <span className="c-ffButton__loadingDot">&#9679;</span>
  </div>
);

const ButtonSkeletonLoading = () => (
  <div>
    <span className="c-ffButton__skeletonLoader"></span>
  </div>
);

const FFButton = ({
  className = '',
  onClick = () => {},
  onMouseDown,
  onMouseUp,
  onMouseLeave,
  children,
  iconType,
  size = 'md',
  variant = 'primary',
  color = 'defaultColor',
  iconSize = 'sm',
  loading = false,
  disabled = false,
  activeClassname,
  active,
  block = false,
  loadingType = 'default',
  tourTriggerId
}: PropsWithChildren<{
  className?: string;
  activeClassname?: string;
  active?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  onMouseDown?: React.MouseEventHandler<any>;
  onMouseUp?: React.MouseEventHandler<any>;
  onMouseLeave?: React.MouseEventHandler<any>;
  iconType?: NewIconName;
  iconSize?: NewIconSize;
  block?: boolean;
  tourTriggerId?: string;
  size?: 'sm' | 'md' | 'lg';
  variant?: 'primary' | 'outlined' | 'help';
  color?: 'defaultColor' | 'dangerColor' | 'warningColor' | 'grayColor' | 'onboardingColor';
  loading?: boolean;
  disabled?: boolean;
  loadingType?: 'default' | 'skeleton';
}>) => {
  const onButtonClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (tourTriggerId) {
      userPilot.trigger(tourTriggerId);
    }
    onClick(e);
  };
  return (
    <Button
      className={[
        'c-ffButton',
        `c-ffButton--${block ? 'block' : 'minimum'}`,
        `c-ffButton--${size}`,
        `c-ffButton--${variant}`,
        `c-ffButton--${color}`,
        loading ? `c-ffButton--loading` : '',
        loadingType === 'skeleton' ? 'c-ffButton--loadingSkeleton' : 'c-ffButton--loadingDefault',
        disabled ? `c-ffButton--disabled` : '',
        iconType ? `c-ffButton--withIcon` : '',
        active ? activeClassname : '',
        className
      ].join(' ')}
      onClick={onButtonClick}
      onMouseDown={onMouseDown}
      onMouseLeave={onMouseLeave}
      onMouseUp={onMouseUp}
      disabled={disabled}
    >
      <VisibilityWrapper visible={!!iconType}>
        <NewIcon name={iconType!} size={iconSize} className="c-ffButton__icon" />
      </VisibilityWrapper>
      <span className="c-ffButton__text">{children}</span>
      <VisibilityWrapper visible={loading}>
        <>
          <VisibilityWrapper visible={loadingType === 'default'}>
            <ButtonLoading />
          </VisibilityWrapper>
          <VisibilityWrapper visible={loadingType === 'skeleton'}>
            <ButtonSkeletonLoading />
          </VisibilityWrapper>
        </>
      </VisibilityWrapper>
    </Button>
  );
};

export default FFButton;