import { FormStore, FormsStoreProps } from '@/types/stores/forms';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { produce } from 'immer';

const DEFAULT_PROPS: FormsStoreProps = {
  offer: {
    isOpen: false,
    data: null,
    isDuplication: false
  },
  category: {
    isOpen: false,
    data: null,
    isDuplication: false,
    categoryType: 'offer'
  },
  offerSource: {
    isOpen: false,
    data: null,
    isDuplication: false
  },
  openedForms: []
};

const useFormStore = create<FormStore>()(
  devtools((set, get) => ({
    ...DEFAULT_PROPS,
    // offer
    openOfferForm: () =>
      set(
        produce<FormStore>(state => {
          state.offer.isOpen = true;
          state.openedForms.push('offer');
        })
      ),
    closeOfferForm: () =>
      set(
        produce<FormStore>(state => {
          state.offer.isOpen = false;
          state.offer.data = null;
          state.offer.isDuplication = false;
          state.openedForms = state.openedForms.filter(form => form !== 'offer');
        })
      ),
    setOfferData: (data, isDuplication) =>
      set(
        produce<FormStore>(state => {
          state.offer.data = data;
          state.offer.isDuplication = isDuplication;
        })
      ),
    // category
    openCategoryForm: categoryType =>
      set(
        produce<FormStore>(state => {
          state.category.isOpen = true;
          state.openedForms.push('category');
          state.category.categoryType = categoryType;
        })
      ),
    closeCategoryForm: () =>
      set(
        produce<FormStore>(state => {
          state.category.isOpen = false;
          state.category.data = null;
          state.category.isDuplication = false;
          state.openedForms = state.openedForms.filter(form => form !== 'category');
        })
      ),
    setCategoryData: (data, isDuplication) =>
      set(
        produce<FormStore>(state => {
          state.category.data = data;
          state.category.isDuplication = isDuplication;
        })
      ),
    setCategoryType: categoryType =>
      set(
        produce<FormStore>(state => {
          state.category.categoryType = categoryType;
        })
      ),
    // offersources
    openOfferSourceForm: () =>
      set(
        produce<FormStore>(state => {
          state.offerSource.isOpen = true;
          state.openedForms.push('offerSource');
        })
      ),
    closeOfferSourceForm: () =>
      set(
        produce<FormStore>(state => {
          state.offerSource.isOpen = false;
          state.offerSource.data = null;
          state.offerSource.isDuplication = false;
          state.openedForms = state.openedForms.filter(form => form !== 'offerSource');
        })
      ),
    setOfferSourceData: (data, isDuplication) =>
      set(
        produce<FormStore>(state => {
          state.offerSource.data = data;
          state.offerSource.isDuplication = isDuplication;
        })
      )
  }))
);

export default useFormStore;
