import { PlusCircleOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import React, { PropsWithChildren, ReactNode } from 'react';
import { VisibilityWrapper } from '@/uikit';
import './style.scss';
import { defined } from '@/utils/define';
import clsx from 'clsx';

interface Props<T> {
  renderRow: (row: T, idx: number) => JSX.Element;
  rows: T[];
  showAddRow: ((rows: T[]) => boolean) | boolean;
  onAddRow?: () => void;
  className?: string;
  rowClassName?: string;
  headerRow?: ReactNode;
  style?: React.CSSProperties;
}

type ColProps = PropsWithChildren<{
  minWidth?: number;
  maxWidth?: number;
  className?: string;
  autoWidth?: boolean;
  gap?: string;
}>;

const FFAddGroupRow = ({
  children,
  className = ''
}: PropsWithChildren<{ className?: string }>) => {
  return (
    <div className={clsx(['c-ffAddGroup__row', className])}>
      {children}
    </div>
  );
};

const FFAddGroupCol = ({
  children,
  minWidth,
  maxWidth,
  autoWidth,
  className = '',
  gap
}: ColProps) => {
  return (
    <div
      className={clsx(['c-ffAddGroup__col', className], {
        'c-ffAddGroup__col--minimumWidth': defined(minWidth)
      })}
      style={{ minWidth, maxWidth, gap, flex: autoWidth ? 'none' : '' }}
    >
      {children}
    </div>
  );
};

const FFAddGroupAddRow = ({ onAddRow }: { onAddRow: () => void }) => {
  return (
    <Divider className="c-ffAddGroup__addDivider">
      <PlusCircleOutlined
        className="c-ffAddGroup__addIcon"
        onClick={onAddRow}
      />
    </Divider>
  );
};

const FFAddGroup = <T extends object | string>({
  renderRow,
  rows,
  showAddRow,
  onAddRow = () => {},
  className = '',
  rowClassName = '',
  headerRow,
  style
}: PropsWithChildren<Props<T>>) => {
  return (
    <div className={clsx(['c-ffAddGroup', className])} style={style}>
      {headerRow}
      {rows.map((row, idx) => {
        if (idx === 0) {
          return (
            <FFAddGroupRow className={rowClassName} key={idx}>
              {renderRow(row, idx)}
            </FFAddGroupRow>
          );
        } else {
          return (
            <FFAddGroupRow className={rowClassName} key={idx}>
              {renderRow(row, idx)}
            </FFAddGroupRow>
          );
        }
      })}
      <VisibilityWrapper
        visible={
          typeof showAddRow === 'boolean' ? showAddRow : showAddRow(rows)
        }
      >
        <FFAddGroupAddRow onAddRow={onAddRow} />
      </VisibilityWrapper>
    </div>
  );
};

FFAddGroup.Col = FFAddGroupCol;
FFAddGroup.Row = FFAddGroupRow;

export { FFAddGroup, FFAddGroupRow, FFAddGroupCol };
export default FFAddGroup;
