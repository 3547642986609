import CodeSnippet from '@/components/CodeSnippet';
import DataPassing from '@/components/DataPassing';
import SectionBox from '@/components/SectionBox';
import { TemplateSelector } from '@/components/TemplateSelector';
import { OFFERSOURCES_CATEGORIES_NOT_DELETED_URL } from '@/constants/swrUrls';
import { OFFER_SOURCE_TEMPLATE } from '@/constants/templates';
import useHttp from '@/hooks/http';
import { Category } from '@/models/category';
import { OfferSource } from '@/models/offerSource';
import useFormStore from '@/stores/forms';
import useSystemSettingsStore from '@/stores/systemSettings';
import { OfferSourceTemplate } from '@/types/offerSource';
import { FFCircleButton, FFCol, FFField, FFIcon, FFInput, FFRow, FFSelect, FFSidePanel, VisibilityWrapper } from '@/uikit';
import FFButton from '@/uikit/components/Button';
import { SidebarTab } from '@/uikit/types/sidebar';
import { getSidebarOffsetLevel } from '@/utils/sidebar';
import swrFetcher from '@/utils/swrFetcher';
import { Ref, forwardRef, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useSWR from 'swr';

type TabId = 'general' | 'dataPassing' | 'conversionTracking' | 'help';

const DEFAULT_POSTBACK_SUBID = 'REQUIRED';
const DEFAULT_POSTBACK_TXID = 'OPTIONAL_TXID';
const DEFAULT_POSTBACK_PAYOUT = 'OPTIONAL_REVENUE';

const DEFAULT_OFFERSOURCE: OfferSource = {
  queryParams: {},
  offerSourceName: '',
  idOfferSource: '',
  postbackPayout: '',
  postbackSubId: '',
  postbackTxId: ''
};

const tabs: SidebarTab[] = [
  {
    title: 'General Settings',
    tabId: 'general',
    icon: <FFIcon name="settings" />
  },
  {
    title: 'Configure Data Passing',
    tabId: 'dataPassing',
    icon: <FFIcon name="dataPassing" />
  },
  {
    title: 'Conversion Tracking',
    tabId: 'conversionTracking',
    icon: <FFIcon name="conversionTracking" />
  },
  {
    title: 'Help',
    tabId: 'help',
    icon: <FFIcon name="question" />
  }
];

interface FormProps {
  closeForm: () => void;
  currentTabId: TabId;
  defaultFormValues: OfferSource;
  submitLoading: boolean;
  setSubmitLoading: (loading: boolean) => void;
}

interface RefType {
  onSave: () => void;
}

const Form = forwardRef(({ currentTabId, closeForm, setSubmitLoading, submitLoading, defaultFormValues }: FormProps, ref: Ref<RefType>) => {
  const { post: httpPost, get: httpGet } = useHttp();

  // swr requests
  const { data: categories = [] } = useSWR<Category[]>(OFFERSOURCES_CATEGORIES_NOT_DELETED_URL, swrFetcher, {
    revalidateOnFocus: false
  });

  // store usages
  const { domains, userSettings } = useSystemSettingsStore();
  const offerSourceFormProps = useFormStore(state => state.offerSource);
  const openCategoryForm = useFormStore(state => state.openCategoryForm);

  // form
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitted },
    getValues,
    setValue,
    watch
  } = useForm<OfferSource>({
    defaultValues: defaultFormValues
  });

  // states
  const [domain, setDomain] = useState<string>(userSettings.defaultCustomDomain);
  const [isSaveAndCreateMode, setIsSaveAndCreateMode] = useState(false);

  const onSaveAndCreate = () => {
    setIsSaveAndCreateMode(true);
    onSave();
  };

  const onSave = handleSubmit(
    async data => {},
    () => {}
  );

  return (
    <>
      <VisibilityWrapper visible={currentTabId === 'general'}>
        <SectionBox title="General Settings">
          <FFCol gap="18px" height="max-content">
            <FFRow gap="8px">
              <Controller
                name="offerSourceName"
                control={control}
                rules={{ required: 'Offer Source Name is required' }}
                render={opt => (
                  <FFField label="Offer Source Name" block>
                    <FFInput
                      value={getValues('offerSourceName')}
                      onChange={opt.field.onChange}
                      error={errors.offerSourceName?.message}
                      placeholder="Offer Source Name"
                    />
                  </FFField>
                )}
              />
              <Controller
                name="idCategory"
                control={control}
                render={opt => (
                  <FFField label="Category">
                    <FFSelect
                      value={getValues('idCategory')}
                      onSelect={opt.field.onChange}
                      options={categories}
                      valueGetter={opt => opt.idCategory}
                      labelGetter={opt => opt.categoryName}
                      defaultValueFallback={{
                        label: 'Uncategorized',
                        value: 'Uncategorized'
                      }}
                      placeholder="Category"
                      style={{ width: 170 }}
                    />
                    <FFCircleButton
                      type="general"
                      iconName="general/line/add-circle"
                      color="blue"
                      onClick={() => openCategoryForm('offersources')}
                    />
                  </FFField>
                )}
              />
            </FFRow>
            <FFButton variant="outlined" onClick={onSaveAndCreate} loading={submitLoading}>
              Save & Create New
            </FFButton>
          </FFCol>
        </SectionBox>
      </VisibilityWrapper>
      <VisibilityWrapper visible={currentTabId === 'dataPassing'}>
        <FFCol gap="18px" height="max-content" width="100%">
          <SectionBox title="Configure Data Passing">
            <p>Here you can configure additional data that gets appended to your base page URL.</p>
            <DataPassing
              queryParams={getValues('queryParams')}
              onChange={queryParams => setValue('queryParams', queryParams)}
              pageName="Data Passing Offers"
              showErrors={isSubmitted}
            />
          </SectionBox>
        </FFCol>
      </VisibilityWrapper>
      <VisibilityWrapper visible={currentTabId === 'conversionTracking'}>
        <FFCol gap="18px" height="max-content" width="100%" paddingBottom="50px">
          <SectionBox title="Postback URL">
            <p>
              Here, enter the tokens this offer source uses to pass back relevant data. The Hit ID field should have the token that
              corresponds to the field you pass Hit ID under in the configure data passing tab.
            </p>
            <FFRow gap="8px" marginBottom="18px">
              <Controller
                name="postbackSubId"
                control={control}
                render={opt => (
                  <FFField label="Hit ID Field" block>
                    <FFInput
                      value={getValues('postbackSubId')}
                      onChange={opt.field.onChange}
                      error={errors.postbackSubId?.message}
                      placeholder="Hit ID Token"
                    />
                  </FFField>
                )}
              />
              <Controller
                name="postbackPayout"
                control={control}
                render={opt => (
                  <FFField label="Offer Payout" block>
                    <FFInput
                      value={getValues('postbackPayout')}
                      onChange={opt.field.onChange}
                      error={errors.postbackPayout?.message}
                      placeholder="Payout Token"
                    />
                  </FFField>
                )}
              />
              <Controller
                name="postbackTxId"
                control={control}
                render={opt => (
                  <FFField label="Transaction ID" block>
                    <FFInput
                      value={getValues('postbackTxId')}
                      onChange={opt.field.onChange}
                      error={errors.postbackTxId?.message}
                      placeholder="Transaction Token"
                    />
                  </FFField>
                )}
              />
            </FFRow>
            <FFRow marginBottom="18px">
              <FFField
                label="Select a domain"
                block
                tootlipContent={
                  <p>
                    Any domain can be used for postback URLs as these rely on a unique hit ID. However, client-side code like Javascript
                    will rely on cookies if you do not inject hit/vid directly, thus you should use the same domain for tracking links and
                    Javascript where possible.
                  </p>
                }
              >
                <FFSelect
                  options={domains}
                  valueGetter={opt => opt.domain}
                  labelGetter={opt => opt.domain}
                  value={domain}
                  onSelect={setDomain}
                  placeholder="Select a domain"
                />
              </FFField>
            </FFRow>
            <FFCol>
              <p>Here is the postback URL to use at this Offer Source:</p>
              <CodeSnippet
                data-testid="postBackUrl"
                placeholder="Postback URL"
                codeType="offerPostbackUrl"
                domain={domain}
                postbackPayout={watch('postbackPayout') || DEFAULT_POSTBACK_PAYOUT}
                postbackTxId={watch('postbackTxId') || DEFAULT_POSTBACK_TXID}
                postbackSubId={watch('postbackSubId') || DEFAULT_POSTBACK_SUBID}
              />
            </FFCol>
          </SectionBox>
          <SectionBox title="Universal Javascript Tag (conversion)">
            <p>
              Here is the full Javascript code to place at this offer to track a conversion. It includes our universal JS tag and a
              conversion event. See our{' '}
              <a href="https://help.funnelflux.pro/article/106-javascript-tracking-of-conversions" target="_blank" rel="noreferrer">
                help documentation
              </a>{' '}
              for more info.
            </p>
            <CodeSnippet
              codeType="offerSourceConversionFull"
              domain={domain}
              postbackPayout={watch('postbackPayout') || DEFAULT_POSTBACK_PAYOUT}
              postbackTxId={watch('postbackTxId') || DEFAULT_POSTBACK_TXID}
              postbackSubId={watch('postbackSubId') || DEFAULT_POSTBACK_SUBID}
            />
          </SectionBox>
          <SectionBox title="Optional: Conversion-only Event">
            <p>If you have already loaded our universal JS tag, you can trigger a conversion with the following code.</p>
            <CodeSnippet
              codeType="offerSourceConversionSingle"
              domain={domain}
              postbackPayout={watch('postbackPayout') || DEFAULT_POSTBACK_PAYOUT}
              postbackTxId={watch('postbackTxId') || DEFAULT_POSTBACK_TXID}
              postbackSubId={watch('postbackSubId') || DEFAULT_POSTBACK_SUBID}
            />
          </SectionBox>
        </FFCol>
      </VisibilityWrapper>
      <VisibilityWrapper visible={currentTabId === 'help'}>
        <SectionBox title="Help">
          <>
            <p>Offer Sources are the places where you get offers from.</p>

            <p>Typically, these will be affiliate networks, but could include direct advertisers and product owners.</p>

            <p>
              The key purpose of offer sources is to <strong>template</strong> the way you pass data in your offers from this source, as
              well as your default conversion tracking approach.
            </p>

            <p>
              By using Offer Sources you can create regular patterns for how you pass data to all your offers from the same source,
              guaranteeing standardised data and reducing tracking errors.
            </p>
            <p>
              For more help on offer sources, see our documentation{' '}
              <a href="https://help.funnelflux.pro/article/5-introduction-to-offer-sources" target="_blank" rel="noopener noreferrer">
                here
              </a>
              .
            </p>
            <p>
              For further information on using our Javascript tracking for offers, see our documentation{' '}
              <a href="https://help.funnelflux.pro/collection/6-javascript-tracking" target="_blank" rel="noopener noreferrer">
                here
              </a>
              .
            </p>
          </>
        </SectionBox>
      </VisibilityWrapper>
    </>
  );
});

const OfferSourceForm = () => {
  const { get: httpGet } = useHttp();
  const isOpen = useFormStore(state => state.offerSource.isOpen);
  const openedForms = useFormStore(state => state.openedForms);
  const closeForm = useFormStore(state => state.closeOfferSourceForm);
  const [currentTabId, setCurrentTabId] = useState<TabId>('general');
  const [showTemplates, setShowTemplates] = useState(true);
  const [templateSearch, setTemplateSearch] = useState('');
  const offerSourceFormProps = useFormStore(state => state.offerSource);
  const formRef = useRef<RefType>({
    onSave: () => {}
  });
  const [defaultFormValues, setDefaultFormValues] = useState<OfferSource>(DEFAULT_OFFERSOURCE);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const func = async () => {
      if (offerSourceFormProps.data?.id) {
        const offerSource = await httpGet<OfferSource>('v1/offersource/find/byId/', {
          params: { idPage: offerSourceFormProps.data?.id }
        });
        setDefaultFormValues(offerSource.data);
      }
      setLoading(false);
    };

    func();
  }, [offerSourceFormProps.data?.id]);

  useEffect(() => {
    if (!isOpen) {
      setDefaultFormValues(DEFAULT_OFFERSOURCE);
      setShowTemplates(true);
      setTemplateSearch('');
    }
  }, [isOpen]);

  const onSelectTemplate = (template?: OfferSourceTemplate) => {
    if (template) {
      setDefaultFormValues({
        idOfferSource: '',
        offerSourceName: template.name,
        queryParams: template.dataPassingFields,
        postbackPayout: template.tracking.postbackPayout,
        postbackTxId: template.tracking.postbackTxId,
        postbackSubId: template.tracking.postbackSubId
      });
    }
    setShowTemplates(false);
  };

  return (
    <FFSidePanel
      isOpen={isOpen}
      minWidth={1000}
      maxWidth={1000}
      tabs={showTemplates ? [] : tabs}
      onClose={closeForm}
      sidebarName="OfferSourceForm"
      defaultTabId="general"
      offsetLevel={getSidebarOffsetLevel(openedForms, 'offerSource')}
      zIndex={100}
      title={
        offerSourceFormProps.isDuplication
          ? 'Duplicate Offer Source'
          : offerSourceFormProps.data?.id
          ? 'Edit Offer Source'
          : 'Create Offer Source'
      }
      setCurrentTabId={tabId => setCurrentTabId(tabId as TabId)}
      actions={
        <FFRow gap="8px">
          <FFButton onClick={() => formRef.current.onSave()} loading={submitLoading} disabled={submitLoading}>
            Save
          </FFButton>
          <FFButton variant="outlined" disabled={submitLoading} onClick={closeForm}>
            Cancel
          </FFButton>
        </FFRow>
      }
    >
      {showTemplates ? (
        <TemplateSelector
          onSelect={onSelectTemplate}
          onSearch={setTemplateSearch}
          searchValue={templateSearch}
          templates={OFFER_SOURCE_TEMPLATE}
          type="offersource"
        />
      ) : loading ? (
        'loading ...'
      ) : (
        <Form
          defaultFormValues={defaultFormValues}
          currentTabId={currentTabId}
          closeForm={closeForm}
          setSubmitLoading={setSubmitLoading}
          submitLoading={submitLoading}
          ref={formRef}
        />
      )}
    </FFSidePanel>
  );
};

export default OfferSourceForm;
