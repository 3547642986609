import className from '@/utils/className';
import './style.scss';
import { PropsWithChildren, ReactNode } from 'react';
import { FFRow } from '@/uikit';
import clsx from 'clsx';

const { blockClassName, getClass } = className('c-sectionBox');

const SectionBox = ({
  title,
  children,
  titlePostfix,
  className
}: PropsWithChildren<{ title?: string; titlePostfix?: ReactNode; className?: string }>) => {
  return (
    <div className={clsx(blockClassName, className)}>
      {title && (
        <FFRow className={getClass('titleArea')} alignItems="center" gap="8px">
          <h3 className={getClass('title')}>{title}</h3>
          {titlePostfix}
        </FFRow>
      )}
      {children}
    </div>
  );
};

export default SectionBox;
