declare global {
  interface Window {
    userpilot: any;
  }
}

function trigger(contentId: string): void {
  window.userpilot.trigger(contentId);
}

function initialize(apiKey: string): void {
  // Your implementation here
}

function identifyUser(userId: string): void {
  // Your implementation here
}


function updateUserProperties(userId: string, properties: Record<string, any>): void {
  // Your implementation here
}

export default { trigger, initialize, identifyUser, updateUserProperties };
