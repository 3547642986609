import useAuthStore from '@/stores/auth';
import { FFNewIcon, FFRow } from '@/uikit';
import { FFLayout } from '@/uikit/components/Layout';
import className from '@/utils/className';
import { gistUtils } from '@/widgets/gist';
import { PropsWithChildren, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const { blockClassName, getClass } = className('c-layout');

const { getClass: getSidebarClass } = className('c-layoutSidebar');

const Layout = ({ children }: PropsWithChildren<{}>) => {
  const [expanded, setExpanded] = useState(false);
  const [showTabs, setShowTabs] = useState(false);
  const [unreadChatCount, setUnreadChatCount] = useState(0);
  const logout = useAuthStore(state => state.logout);
  const navigate = useNavigate();

  const onCreateNewAssetClick = () => {

  }

  const onLogout = () => {
    logout();
    navigate('/');
  }

  const onShowPartners = () => {

  }

  return (
    <FFLayout>
      <FFLayout.Sider
        collapsed={!expanded}
        onToggle={() => setExpanded(!expanded)}
        onCreateNewAsset={onCreateNewAssetClick}
      >
        <FFLayout.Menu>
          <FFLayout.FFMenuItem
            key="Dashboard"
            title="Dashboard"
            data-onboarding-id="Dashboard"
            to="/summary"
            single
            icon={<FFNewIcon name="navigation/dashboard" size="navigation-md" />}
            popupClassName={getSidebarClass('popup')}
            className={getSidebarClass('subMenu', ['singleMenu'])}
          />
          <FFLayout.FFMenuItem
            key="Funnels"
            title="Funnels"
            data-onboarding-id="Funnels"
            to="/funnels"
            single
            icon={<FFNewIcon name="navigation/funnels" size="navigation-md" />}
            popupClassName={getSidebarClass('popup')}
            className={getSidebarClass('subMenu', 'singleMenu')}
          />
          <FFLayout.FFMenuItem
            key="Assets"
            title="Assets"
            data-onboarding-id="Assets"
            icon={<FFNewIcon name="navigation/assets" size="navigation-md" />}
          >
            <FFLayout.FFMenuSubItem key="TrafficSources" data-onboarding-id="TrafficSources" to="/trafficsources">
              <FFNewIcon name="navigation/traffic-sources" size="navigation-md" /> Traffic Sources
            </FFLayout.FFMenuSubItem>
            <FFLayout.FFMenuSubItem key="offers" data-onboarding-id="Offers" to="/offers">
              <FFNewIcon name="navigation/offers" size="navigation-md" /> Offers
            </FFLayout.FFMenuSubItem>
            <FFLayout.FFMenuSubItem key="offersources" data-onboarding-id="OfferSources" to="/offersources">
              <FFNewIcon name="navigation/offer-sources" size="navigation-md" /> Offer Sources
            </FFLayout.FFMenuSubItem>
            <FFLayout.FFMenuSubItem key="Landers" data-onboarding-id="Landers" to="/landers">
              <FFNewIcon name="navigation/landers" size="navigation-md" /> Landers
            </FFLayout.FFMenuSubItem>
            <FFLayout.FFMenuSubItem key="PageGroups" data-onboarding-id="PageGroups" to="/pagegroups">
              <FFNewIcon name="navigation/page-groups" size="navigation-md" /> Page Groups
            </FFLayout.FFMenuSubItem>
            <FFLayout.FFMenuSubItem key="Conditions" data-onboarding-id="Conditions" to="/conditions">
              <FFNewIcon name="navigation/conditions" size="navigation-md" /> Conditions
            </FFLayout.FFMenuSubItem>
          </FFLayout.FFMenuItem>
          <FFLayout.FFMenuItem
            key="Reporting"
            title="Reporting"
            data-onboarding-id="Reporting"
            icon={<FFNewIcon name="navigation/reporting" size="navigation-md" />}
          >
            <FFLayout.FFMenuSubItem key="Report Builder" data-onboarding-id="ReportBuilder" to="/reporting">
              <FFNewIcon name="navigation/report-builder" size="navigation-md" /> Report Builder
            </FFLayout.FFMenuSubItem>
            <FFLayout.FFMenuSubItem key="CampaignAnalysis" data-onboarding-id="CampaignAnalysis" to="/campaignanalysis">
              <FFNewIcon name="navigation/campaign-analysis" size="navigation-md" /> Campaign Analysis
            </FFLayout.FFMenuSubItem>
            <FFLayout.FFMenuSubItem key="RawEvents" data-onboarding-id="RawEvents" to="/raw/hits">
              <FFNewIcon name="navigation/raw-events" size="navigation-md" /> Raw Events
            </FFLayout.FFMenuSubItem>
            <FFLayout.FFMenuItem
              key="IntegratedReporting"
              title="Integrated Reporting"
              data-onboarding-id="IntegratedReporting"
              level={2}
              icon={<FFNewIcon name="navigation/integrated-reporting" size="navigation-md" />}
            >
              <FFLayout.FFMenuSubItem key="GoogleAds" data-onboarding-id="GoogleAds" to="/integrated-reporting/google-ads">
                Google Ads
              </FFLayout.FFMenuSubItem>
              <FFLayout.FFMenuSubItem key="Facebook" data-onboarding-id="Facebook" to="/integrated-reporting/facebook-ads">
                Facebook Ads
              </FFLayout.FFMenuSubItem>
            </FFLayout.FFMenuItem>
          </FFLayout.FFMenuItem>
          <FFLayout.FFMenuItem
            key="DataUpdates"
            title="Data Updates"
            icon={<FFNewIcon name="navigation/updates" data-onboarding-id="DataUpdates" size="navigation-md" />}
          >
            <FFLayout.FFMenuSubItem key="UpdateTrafficCosts" data-onboarding-id="UpdateTrafficCosts" to="/updatetrafficcosts">
              <FFNewIcon name="navigation/update-costs" size="navigation-md" /> Update Traffic Costs
            </FFLayout.FFMenuSubItem>
            <FFLayout.FFMenuSubItem key="UpdateConversions" data-onboarding-id="UpdateConversions" to="/updateconversions">
              <FFNewIcon name="navigation/update-conversions" size="navigation-md" /> Update Conversions
            </FFLayout.FFMenuSubItem>
            <FFLayout.FFMenuSubItem key="ResetData" data-onboarding-id="ResetData" to="/resetdata">
              <FFNewIcon name="navigation/reset-data" size="navigation-md" /> Reset Data
            </FFLayout.FFMenuSubItem>
          </FFLayout.FFMenuItem>
          <FFLayout.FFMenuItem
            key="Labs"
            title="Labs"
            data-onboarding-id="Labs"
            icon={<FFNewIcon name="navigation/labs" size="navigation-md" />}
          >
            <FFLayout.FFMenuSubItem key="FunnelFluxLabs" data-onboarding-id="FunnelFluxLabs" to="/labs">
              <FFNewIcon name="navigation/labs-2" size="navigation-md" /> FunnelFlux Labs
            </FFLayout.FFMenuSubItem>
            <FFLayout.FFMenuSubItem key="TrafficFiltering" data-onboarding-id="TrafficFiltering" to="/traffic-filtering">
              <FFNewIcon name="navigation/traffic-filtering" size="navigation-md" /> Traffic Filtering
            </FFLayout.FFMenuSubItem>
            <FFLayout.FFMenuSubItem key="KeywordRotationLinks" data-onboarding-id="KeywordRotationLinks" to="/keyword-rotation-links">
              <FFNewIcon name="navigation/keyword-links" size="navigation-md" /> Keyword Rotation Links
            </FFLayout.FFMenuSubItem>
          </FFLayout.FFMenuItem>
          <FFLayout.FFMenuItem
            key="Settings"
            title="Settings"
            data-onboarding-id="Settings"
            icon={<FFNewIcon name="navigation/settings" size="navigation-md" />}
          >
            <FFLayout.FFMenuSubItem key="Domains" data-onboarding-id="Domains" to="/domains">
              <FFNewIcon name="navigation/domains" size="navigation-md" /> Domains
            </FFLayout.FFMenuSubItem>
            <FFLayout.FFMenuSubItem key="TrackingCodes" data-onboarding-id="TrackingCodes" to="/trackingcodes">
              <FFNewIcon name="navigation/tracking-codes" size="navigation-md" /> Tracking Codes
            </FFLayout.FFMenuSubItem>
            <FFLayout.FFMenuItem
              key="Integrations"
              title="Integrations"
              data-onboarding-id="Integrations"
              level={2}
              icon={<FFNewIcon name="navigation/integrations" size="navigation-md" />}
            >
              <FFLayout.FFMenuSubItem key="TrafficSources" data-onboarding-id="TrafficSources" to="/integrations/trafficsources">
                Traffic Sources
              </FFLayout.FFMenuSubItem>
              <FFLayout.FFMenuSubItem key="OfferSources" data-onboarding-id="OfferSources" to="/integrations/offersources">
                Offer Sources
              </FFLayout.FFMenuSubItem>
              <FFLayout.FFMenuSubItem
                key="IntegratedUIConnections"
                data-onboarding-id="IntegratedUIConnections"
                to="/integrations/integrated-uis"
              >
                Integrated UIs
              </FFLayout.FFMenuSubItem>
              <FFLayout.FFMenuSubItem key="Others" data-onboarding-id="Others" to="/integrations/others">
                Others
              </FFLayout.FFMenuSubItem>
            </FFLayout.FFMenuItem>
            <FFLayout.FFMenuSubItem key="SubscriptionBilling" data-onboarding-id="SubscriptionBilling" to="/subscriptionbilling">
              <FFNewIcon name="navigation/billing" size="navigation-md" /> Subscription & Billing
            </FFLayout.FFMenuSubItem>
            <FFLayout.FFMenuSubItem key="SystemSettings" data-onboarding-id="SystemSettings" to="/systemsettings">
              <FFNewIcon name="navigation/system-settings" size="navigation-md" /> System Settings
            </FFLayout.FFMenuSubItem>
            <FFLayout.FFMenuSubItem key="APIAccess" data-onboarding-id="APIAccess" to="/api-access">
              <FFNewIcon name="navigation/api-access" size="navigation-md" /> API Access
            </FFLayout.FFMenuSubItem>
          </FFLayout.FFMenuItem>
          <FFLayout.FFMenuItem
            key="HelpAndSupport"
            title="Help and Support"
            data-onboarding-id="HelpAndSupport"
            icon={<FFNewIcon name="navigation/support" size="navigation-md" />}
          >
            <FFLayout.FFMenuSubItem key="ContactSupport" data-onboarding-id="ContactSupport" onClick={gistUtils.open}>
              <FFNewIcon name="navigation/support" size="navigation-md" /> Contact Support
            </FFLayout.FFMenuSubItem>
            <FFLayout.FFMenuSubItem key="Knowledgebase" data-onboarding-id="Knowledgebase" href="https://help.funnelflux.pro">
              <FFNewIcon name="navigation/knowledgebase" size="navigation-md" /> Knowledgebase
            </FFLayout.FFMenuSubItem>
            <FFLayout.FFMenuSubItem key="Release Notes" data-onboarding-id="ReleaseNotes" href="https://funnelflux.canny.io/changelog">
              <FFNewIcon name="navigation/changelog" size="navigation-md" /> Release Notes
            </FFLayout.FFMenuSubItem>
            <FFLayout.FFMenuSubItem key="Roadmap" data-onboarding-id="Roadmap" href="https://funnelflux.canny.io/">
              <FFNewIcon name="navigation/roadmap" size="navigation-md" /> Roadmap
            </FFLayout.FFMenuSubItem>
            <FFLayout.FFMenuSubItem key="RequestFeatures" data-onboarding-id="RequestFeatures" href="https://funnelflux.canny.io/requests">
              <FFNewIcon name="navigation/changelog" data-onboarding-id="Changelog" size="navigation-md" /> Request Features
            </FFLayout.FFMenuSubItem>
            <FFLayout.FFMenuSubItem key="StatusPage" data-onboarding-id="StatusPage" href="https://status.funnelflux.pro/">
              <FFNewIcon name="navigation/status-page" size="navigation-md" /> Status Page
            </FFLayout.FFMenuSubItem>
          </FFLayout.FFMenuItem>
        </FFLayout.Menu>
        <FFLayout.MenuFooter>
          <FFLayout.MenuFooterItem onClick={onLogout} data-onboarding-id="Logout">
            <FFNewIcon name="navigation/logout" size="navigation-md" />
          </FFLayout.MenuFooterItem>
          <FFLayout.MenuFooterItem
            onClick={onShowPartners}
            data-onboarding-id="MarketplaceFooter"
            visible={expanded}
          >
            <FFNewIcon name="navigation/marketplace" size="navigation-md" />
          </FFLayout.MenuFooterItem>
          <FFLayout.MenuFooterItem data-onboarding-id="ChangelogFooter" data-canny-changelog visible={expanded}>
            <FFNewIcon name="navigation/changelog" size="navigation-md" />
          </FFLayout.MenuFooterItem>
          <FFLayout.MenuFooterItem
            href="https://help.funnelflux.pro"
            data-onboarding-id="KnowledgebaseFooter"
            visible={expanded}
          >
            <FFNewIcon name="navigation/support" size="navigation-md" />
          </FFLayout.MenuFooterItem>
          <FFLayout.MenuFooterItem
            onClick={gistUtils.open}
            data-onboarding-id="ContactSupportFooter"
            unreadChatCount={unreadChatCount}
          >
            <FFNewIcon name="navigation/chat" size="navigation-md" />
          </FFLayout.MenuFooterItem>
        </FFLayout.MenuFooter>
      </FFLayout.Sider>
      <FFLayout.Main>
        {showTabs && (
          <FFLayout.Header>
            <FFRow>
              {/* <div className={getClass('editorTabs')}>
                <FFIcon name="visualFunnelBuilder" />
                <span className={getClass('editorTabsText')}>Visual Editor Tabs</span>
              </div>
              <div className={getClass('editorTabsWrapper')}>
                {this.props.currentActiveItem === 'funnels' &&
                  this.state.funnels.map((funnel: Funnel) => (
                    <NavItem
                      funnel={funnel}
                      modal={modalProps}
                      deleteFunnelEditor={this.props.deleteFunnelEditor}
                      key={funnel.idFunnel}
                      history={this.props.history}
                      subscriptionStatus={this.props.subscriptionStatus}
                    />
                  ))}

                <FFRow alignItems="center" gap="8px">
                  <FFIcon
                    name="newFunnelEditorTab"
                    className={getClass('newEditorIcon')}
                    data-testid="newEditorTabBtn"
                    onClick={() => this.newEditor(modalProps)}
                  />
                  <VisibilityWrapper visible={Boolean(this.state.funnels.length)}>
                    <FFIcon name="xCircle" className={getClass('closeAllTabsIcon')} onClick={() => this.closeAllTabs(modalProps)} />
                  </VisibilityWrapper>
                </FFRow>
              </div> */}
            </FFRow>
          </FFLayout.Header>
        )}
        <FFLayout.Content>{children}</FFLayout.Content>
      </FFLayout.Main>
    </FFLayout>
  );
};

export default Layout;
