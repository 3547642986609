import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <g id="delete-2--remove-bold-add-button-buttons-delete-cross-x-mathematics-multiply-math">
      <path
        id="Union"
        fill="currentColor"
        fill-rule="evenodd"
        d="M7.212154285714285 1.7143714285714287c-0.669462857142857 -0.6694765714285714 -1.7548971428571427 -0.6694748571428571 -2.4243599999999996 0L1.7142617142857142 4.787897142857143c-0.6694731428571428 0.66948 -0.6694697142857142 1.7548971428571427 0 2.4243599999999996l4.787818285714286 4.787828571428571L1.7142617142857142 16.78789714285714c-0.6694714285714286 0.6695314285714286 -0.6694697142857142 1.7548457142857141 0 2.424445714285714L4.787794285714285 22.285714285714285c0.669462857142857 0.6696 1.7548971428571427 0.6696 2.4243599999999996 0l4.787828571428571 -4.787657142857143L16.787794285714284 22.285714285714285c0.669462857142857 0.6696 1.7549485714285713 0.6696 2.424377142857143 0L22.285714285714285 19.212342857142858c0.6694285714285714 -0.6696 0.6694285714285714 -1.7549142857142856 0 -2.424445714285714l-4.787828571428571 -4.787811428571428L22.285714285714285 7.212257142857143c0.6694285714285714 -0.669462857142857 0.6694285714285714 -1.7548971428571427 0 -2.4243599999999996l-3.073542857142857 -3.073525714285714c-0.6694285714285714 -0.6694765714285714 -1.7549142857142856 -0.6694748571428571 -2.424377142857143 0L11.999982857142857 6.5021828571428575l-4.787828571428571 -4.787811428571428Z"
        clip-rule="evenodd"
        stroke-width="1"
      ></path>
    </g>
  </svg>
);
