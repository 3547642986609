import React, { PropsWithChildren } from 'react';
import './style.scss';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import className from '@/utils/className';

const { blockClassName, getClass } = className('c-ffField');

const Field = ({
  label,
  children,
  error,
  htmlFor,
  block = false,
  wdith,
  tootlipContent,
  direction = 'column'
}: PropsWithChildren<{
  label: string;
  error?: string;
  htmlFor?: string;
  block?: boolean;
  wdith?: string;
  direction?: 'column' | 'row';
  tootlipContent?: string | JSX.Element;
}>) => (
  <div
    className={[blockClassName, block ? `${blockClassName}--block` : `${blockClassName}--minimum`, `${blockClassName}--${direction}`].join(
      ' '
    )}
    style={{ minWidth: wdith, maxWidth: wdith }}
  >
    <label htmlFor={htmlFor} className={getClass('label')}>
      <span className={getClass('labelText')}>{label}</span>
      {tootlipContent && (
        <Tooltip className={getClass('tooltip')} overlayClassName={getClass('tooltipOverlay')} title={tootlipContent}>
          <InfoCircleOutlined />
        </Tooltip>
      )}
    </label>
    <div className={getClass('childContent')}>{children}</div>
    {error && <span className={getClass('error')}>{error}</span>}
  </div>
);

export default Field;
