import { lazyImport } from '@/utils/lazyImport';

const { LoginRoutes } = lazyImport(() => import('@/features/Login'), 'LoginRoutes');

export const publicRoutes = [
  {
    path: '/',
    element: <LoginRoutes />,
  },
];
