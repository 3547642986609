import { FFNewIcon } from '@/uikit';
import className from '@/utils/className';
import { message } from 'antd';
import './style.scss';

const { blockClassName } = className('c-copyButton');

const CopyButton = ({ value }: { value: string }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const handleClick = async () => {
    try {
      await navigator.clipboard.writeText(value);
      success();
    } catch (err) {
      warning();
    }
  };

  const success = (content = 'Copied') => {
    messageApi.open({
      type: 'success',
      content
    });
  };

  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'Nothing to be copied'
    });
  };

  return (
    <>
      {contextHolder}
      <div className={blockClassName} onClick={handleClick}>
        <FFNewIcon name="general/line/copy-link" size="md" />
      </div>{' '}
    </>
  );
};

export default CopyButton;
