import React from 'react';

const AddCircle = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.855 -0.855 24 24">
    <g>
      <path
        id="Vector"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.145 21.49392857142857c5.715633642857143 0 10.348928571428571 -4.633294928571428 10.348928571428571 -10.348928571428571C21.49392857142857 5.429445964285714 16.86063364285714 0.7960714285714285 11.145 0.7960714285714285 5.429445964285714 0.7960714285714285 0.7960714285714285 5.429445964285714 0.7960714285714285 11.145c0 5.715633642857143 4.633374535714285 10.348928571428571 10.348928571428571 10.348928571428571Z"
        strokeWidth="1.71"
      ></path>
      <path
        id="Vector_2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.145 6.368571428571428v9.552857142857142"
        strokeWidth="1.71"
      ></path>
      <path
        id="Vector_3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.368571428571428 11.145h9.552857142857142"
        strokeWidth="1.71"
      ></path>
    </g>
  </svg>
);

export default AddCircle;