export function valueOrNull<T>(value: T | null | undefined): T | null {
  return value === undefined ? null : value;
}

export function valueOrUndefined<T>(value: T | null | undefined): T | undefined {
  return value === null ? undefined : value;
}

export function valueOrDefault<T>(value: T | null | undefined, defaultValue: T): T {
  return defined(value) ? value : defaultValue;
}

export function defined<T>(v: T | null | undefined): v is T {
  return v !== undefined && v !== null;
}

export function definedAndNotEmpty(v: string | null | undefined): boolean {
  return v !== undefined && v !== null && v !== '';
}

export function mustBeDefined<T>(v: T | null | undefined): v is T {
  if (!defined(v)) {
    throw new TypeError('value must be defined');
  }
  return true;
}

export function definedObject<T>(v: T | null | undefined): v is T {
  //@TODO: remove ts-ignore later
  //@ts-ignore
  return defined(v) ? Object.keys(v).length > 0 : false;
}

export function definedAndNonEmptyArray<T>(v: T[] | null | undefined): v is T[] {
  return defined(v) ? v.length > 0 : false;
}
