import config from '@/config';
import { VerifyAuthenticationResponse } from '@/models/authenticate';
import useAuthStore from '@/stores/auth';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

const useHttp = (headers: Record<string, string> = {}) => {
  const accessToken = useAuthStore(state => state.tokens.access_token);
  const refreshToken = useAuthStore(state => state.tokens.refresh_token);
  const idToken = useAuthStore(state => state.tokens.id_token);
  const expiresAt = useAuthStore(state => state.tokens.expires_at);
  const logout = useAuthStore(state => state.logout);
  const initializeAppAuth = useAuthStore(state => state.initializeAppAuth);
  const navigate = useNavigate();

  const axiosInstance: AxiosInstance = axios.create({
    headers,
    baseURL: config.apiUrls.funnelflux
  });

  axiosInstance.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  });

  axiosInstance.interceptors.response.use(
    response => response,
    async (error: AxiosError) => {
      const canRetry =
        !error.config?.url?.includes('/v1/auth/validate') &&
        (error.response?.status === 401 || new Date(Date.now()).getTime() >= expiresAt);
        
      if (error.response && canRetry) {
        try {
          const verifyAuth = await axiosInstance.post<VerifyAuthenticationResponse>('/v1/auth/validate', {
            idToken: idToken,
            refreshToken: refreshToken,
            accessToken: accessToken
          });
          initializeAppAuth(verifyAuth.data);
        } catch (e) {
          logout();
          navigate('/');
        }
      }
      return Promise.reject(error);
    }
  );

  function get<T>(url: string, options?: AxiosRequestConfig<T>) {
    return axiosInstance.get<T>(url, options);
  }

  function post<T, R>(url: string, body: T, options?: AxiosRequestConfig<T>) {
    return axiosInstance.post<T, R>(url, body, options);
  }

  function put<T>(url: string, body: T, options?: AxiosRequestConfig<T>) {
    return axiosInstance.put<T>(url, body, options);
  }

  function del<T>(url: string, options?: AxiosRequestConfig<T>) {
    return axiosInstance.delete(url, options);
  }

  return { get, post, put, del };
};

export default useHttp;
