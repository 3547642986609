import React from 'react';
import { OfferSourceTemplate } from '@/types/offerSource';
import { TrafficSourceTemplate } from '@/types/trafficsource';
import { sortByName } from '@/utils/sort';
import { FFInput } from '@/uikit';
import className from '@/utils/className';
import clsx from 'clsx';
import './style.scss';

const { blockClassName, getClass } = className('c-templateSelector');

export const TemplateSelector = <T extends TrafficSourceTemplate | OfferSourceTemplate>({
    onSelect,
    onSearch,
    searchValue,
    templates = [],
    type,
  }: {
    onSelect: (template?: T) => void;
    onSearch: (value: string) => void;
    searchValue: string;
    templates: T[],
    type: 'offersource' | 'trafficsource'
  }) => (
    <div className={clsx([blockClassName, getClass(type)])}>
      <div className={getClass('search')}>
        <FFInput
          id="tsTemplateSearch"
          name="tsTemplateSearch"
          placeholder="Search to filter available templates"
          value={searchValue}
          onChange={e => onSearch(e.target.value)}
        />
      </div>
      <div className={getClass('templates')}>
        <div
          className={getClass('template')}
          onClick={() => onSelect()}
        >
          <span className="font-size-16">Create a custom source</span>
          <span className="font-size-26">+</span>
        </div>
        {sortByName(templates, 'name')
          .filter(template => template.name.toLowerCase().includes(searchValue))
          .map(template => (
            <div
              className={getClass('template')}
              onClick={() => onSelect(template)}
              key={template.id}
            >
              <img
                className={`${blockClassName}__template--img ${blockClassName}__template--img--${template.id}`}
                src={template.imgSrc}
                alt={template.name}
              />
            </div>
          ))}
      </div>
    </div>
  );
